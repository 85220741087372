import React from "react";

import {card} from "../styles/info.css";

const Info = () => {
    return (
        <>
            <h2>The Digital Experts</h2>
            <p>
                Eclipse IT Consulting provide expertise in delivering digital
                technologies, using an Agile approach. From a simple web presence for a
                small business, to large complex multi-million pound applications; no digital product or project is too
                big or too small.
            </p>
            <p>
                Established in 2009, we have consultants with over twenty years
                experience providing expertise in areas including:
            </p>
            <ul>
                <li>Digital Solution Architecture</li>
                <li>Agile Software Engineering</li>
                <li>Test Driven Development</li>
                <li>Continuous Integration</li>
                <li>Agile Coaching</li>
            </ul>
            <p>Depending on your need, we are able to provide either; end-to-end digital product delivery, for instance
                designing, building
                and hosting your companies web site; or technical expertise as part of a large-scale product team.</p>
            <div className={card}>
                <p>
                    For more information on SME digital solutions, such as web design and hosting, please contact Georgina on{" "}
                    <a href="mailto:georgina@eclipseitconsulting.co.uk">georgina@eclipseitconsulting.co.uk</a>
                </p>
                <p>
                    For large business consulting, please contact Ben on <a
                    href="mailto:ben@eclipseitconsulting.co.uk">ben@eclipseitconsulting.co.uk</a>{" "}
                </p>
            </div>
        </>
    );
};

export default Info;
