import React from "react";
import Footer from "../components/footer";
import Info from "../components/info";

import {footerBackground, grid, hero, title,} from "../styles/info.css";

const InfoPage = () => {
    return (
        <>
            <div className={hero}>
                <header>
                    <h1 className={title}>Eclipse IT Consulting</h1>
                </header>
            </div>
            <div className={grid}>
                <main>
                    <Info/>
                </main>
                <div className={footerBackground}/>
                <footer>
                    <Footer/>
                </footer>
            </div>
        </>
    );
};

export const Head = () => (
    <>
        <title>Eclipse IT Consulting, UK. The Digital Experts</title>
    </>
);

export default InfoPage;
