import React from "react";
import {footerContact, footerGrid, footerOffice, footerTitle, linkedInLogo, logo} from "../styles/footer.css";

import LinkedInLogo from "../images/LinkedInLogo.png"

const Footer = () => {
    return (
        <div className={footerGrid}>
            <div className={footerTitle}>
                <p className={logo}>Eclipse IT Consulting</p>
            </div>
            <div className={footerOffice}>
                <p>OFFICE</p>
                <p>11 Cresttor Road, Liverpool, Merseyside, L25 6DN</p>
            </div>
            <div className={footerContact}>
                <p>CONTACT</p>
                <p>
                    <a href="mailto:georgina@eclipseitconsulting.co.uk">
                        georgina@eclipseitconsulting.co.uk
                    </a>
                </p>
                <p>
                    <a href="https://www.linkedin.com/in/benwilkeseclipseitconsulting">
                        <img src={LinkedInLogo} alt="Linked In" className={linkedInLogo}/>
                    </a>
                </p>
            </div>
        </div>
    );
};

export default Footer;
